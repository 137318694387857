<template>
  <div class="appointment" @click="goDetails">
    <div class="nameOrPrice">
      <div class="name shengluehao">{{ appointmentInfo?.job }}</div>
      <div class="pirce">{{appointmentInfo?.salaryNote}}</div>
    </div>
    <div class="tagList" style="height: 28.8px">
      <div class="item" v-for="item in appointmentInfo?.tagList" :key="item">{{ item }}</div>
    </div>
    <div class="bottom">
      <div class="enterpriseInfo">
        <img style="border-radius:6.67px" :src="info?.logo?.url" alt="">
        <div class="right">
          <div class="name">{{ info?.name }}</div>
          <div style="display: flex">
            <div class="tag" v-for="item in info?.tags" :key="item">{{ item }}<span>·</span></div>
          </div>
        </div>
      </div>
      <div class="adress">{{ item?.areaName }}</div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    appointmentInfo: {
      type: Object,
      default: () => {
      }
    },
    info: {
      type: Object,
      default: () => {
      }
    },
    index: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    goDetails() {
      this.$emit('click', this.appointmentInfo)
      this.$emit('clickIndex', this.index)
      // this.$router.push('/positionDetails')
    }
  }
}
</script>

<style lang="scss" scoped>
.appointment {
  margin: 16px;

  .bottom {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;

    .adress {
      margin-top: 18px;
      color: #808080;
      font-size: 12px;
      line-height: 16px;
    }

    .enterpriseInfo {
      display: flex;
      align-items: center;
      gap: 6px;

      img {
        width: 30px;
        height: 30px;
      }

      .right {
        display: flex;
        flex-direction: column;

        .name {
          font-size: 12px;
          color: #282828;
          font-weight: 400;
        }

        .tag {
          color: #808080;
          font-size: 12px;
          line-height: 16px;
          display: flex;

          span {
            display: block;
          }
        }

        .tag:not(:last-child) {
          span {
            display: block !important;
          }
        }
      }
    }
  }

  .tagList {
    margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 6px;

    .item {
      padding: 6px 12px;
      background: #F6F6F6;
      border-radius: 4px;
      color: #555555;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .nameOrPrice {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      color: #141414;
      font-size: 16px;
      font-weight: 500;
    }

    .pirce {
      color: #FD5056;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
</style>
