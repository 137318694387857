<template>
  <div class="companyInfo">
    <div class="title">公司介绍</div>
    <div class="content">{{ info?.info }}</div>
    <div class="borders"></div>
    <div class="title">公司相册</div>
    <div class="imgList">
      <el-image
          style="width: 206px;height: 120px;border-radius: 8px;"
          v-for="(item, index) in info?.photos" :key="index" :src="item.url"
          :preview-src-list="info?.photos?.map((item) => item.url)">
      </el-image>
      <!--      <img v-for="(item, index) in info?.photos" :key="index" :src="item.url">-->
      <!--       <img src="https://static.dasdt.cn/images/companyImg.png">-->
      <!--       <img src="https://static.dasdt.cn/images/companyImg.png">-->
      <!--       <img src="https://static.dasdt.cn/images/companyImg.png">-->
      <!--       <img src="https://static.dasdt.cn/images/companyImg.png">-->
    </div>
    <div class="borders"></div>
    <div class="title">工商信息</div>
    <el-descriptions style="margin-top: 20px" border :column="2">
      <el-descriptions-item label="企业名称">{{ info?.name }}</el-descriptions-item>
      <el-descriptions-item label="成立日期">{{ info?.foundDate }}</el-descriptions-item>
      <el-descriptions-item label="所属地区">{{ info?.cityName }}</el-descriptions-item>
      <el-descriptions-item label="法定代表人">{{ info?.contact }}</el-descriptions-item>
      <el-descriptions-item label="企业类型"><span style="margin-right: 5px" v-for="item in info?.tags" :key="item">{{ item }}</span> </el-descriptions-item>
      <el-descriptions-item label="注册地址">{{ info?.address }}</el-descriptions-item>
    </el-descriptions>
    <div class="ViewMoreWrap">
      <div class="ViewMore" @click="dialogVisible = true">查看更多</div>
    </div>
    <div class="borders"></div>
    <div class="title">公司地址</div>
    <div class="adresss">
      <div class="item">
        <img src="https://static.dasdt.cn/images/adress2.png">
        <span>{{ info?.address }}</span>
      </div>
      <div class="item">
        <img style="margin-right: 2px" src="https://static.dasdt.cn/images/phone2.png">
        <span>联系电话：{{ info?.phone }}</span>
      </div>
    </div>
    <div style="height: 300px;width: 100%;margin-top: 16px">
      <MapContainer :info="info"/>
    </div>
    <!--    <div style="height: 300px;width: 100%;margin-top: 16px">-->
    <!--      <MapContainer id="container2" :info="info"/>-->
    <!--    </div>-->

    <el-dialog
        :visible.sync="dialogVisible"
        width="1200px"
        title="工商信息"
        :before-close="handleClose">
      <div class="gongshangInfo">
        <el-descriptions border :column="2">
          <el-descriptions-item label="企业名称">{{ info?.name }}</el-descriptions-item>
          <el-descriptions-item label="成立日期">{{ info?.foundDate }}</el-descriptions-item>
          <el-descriptions-item label="所属地区">{{ info?.cityName }}-{{ info?.areaName }}</el-descriptions-item>
          <el-descriptions-item label="法定代表人">{{ info?.contact }}</el-descriptions-item>
          <el-descriptions-item label="企业类型"><span style="margin-right: 5px" v-for="item in info?.tags" :key="item">{{ item }}</span></el-descriptions-item>
          <el-descriptions-item label="注册地址">{{ info?.address }}</el-descriptions-item>
          <!--          <el-descriptions-item label="经营状态">存续</el-descriptions-item>-->
          <!--          <el-descriptions-item label="经营期限">2001-01-16至2040-01-15</el-descriptions-item>-->
          <!--          <el-descriptions-item label="统一社会信用代码">91310000703099764Y</el-descriptions-item>-->
          <el-descriptions-item label="注册资本">{{ info?.registeredCapital }}</el-descriptions-item>
          <!--          <el-descriptions-item label="核准日期">2023-09-14</el-descriptions-item>-->
          <!--          <el-descriptions-item label="曾用名">&#45;&#45;</el-descriptions-item>-->
          <!--          <el-descriptions-item label="登记机关">自由贸易试验区市场监督管理局</el-descriptions-item>-->
          <!--          <el-descriptions-item label="所属行业">科技推广和应用服务业</el-descriptions-item>-->
          <!--          <el-descriptions-item label="经营范围">-->
          <!--            一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；软件开发；软件销售；软件外包服务；网络与信息安全软件开发；人工智能应用软件开发；大数据服务；数据处理服务；互联网数据服务；数据处理和存储支持服务；计算机软硬件及辅助设备批发；计算机软硬件及辅助设备零售。-->
          <!--          </el-descriptions-item>-->
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import MapContainer from "@/components/MapContainer/index.vue";

export default {
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {MapContainer},
  data() {
    return {
      photos: [],
      dialogVisible: false,

    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>
<style scoped lang="scss">
.companyInfo {
  .gongshangInfo {
    padding-bottom: 20px;
  }

  .adresss {
    display: flex;
    align-items: center;
    margin-top: 16px;

    .item {
      width: 50%;
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }

      span {
        color: #555555;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .ViewMoreWrap {
    margin-top: 16px;
    display: flex;
    justify-content: center;

    .ViewMore {
      cursor: pointer;
      border: 1px solid #185BE0;
      border-radius: 4px;
      width: 200px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #185BE0;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .title {
    color: #141414;
    font-size: 18px;
    font-weight: 500;
  }

  .imgList {
    flex-wrap: wrap;
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 16px;

    img {
      width: 206px;
      height: 120px;
      border-radius: 8px;
    }

  }

  .borders {
    border-bottom: 1px solid #E6E6E6;
    margin: 20px 0;
  }

  .content {
    color: #333333;
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
    margin-top: 20px;
  }
}
</style>
