<template>
  <div class="recruitmentPositions">
    <div class="detailsWrap">
      <div class="AppointmentWrap" style="flex-direction: column;align-items: flex-start;max-height: 1400px;
    overflow-y: auto;flex-wrap: nowrap">
        <div class="Item" v-for="(item, index) in info?.jobList" :key="index" style="border: 1px solid #E6E6E6"
             :class="{current: current===index}">
          <Appointment :appointmentInfo="item" :info="info" :item="item" :index="index" @click="getDetails"
                       @clickIndex="clickIndex"/>
        </div>
      </div>
      <div class="details">
        <div class="header">
          <div class="info">
            <div class="name">{{ details?.job }}</div>
            <div class="infoList">
              <div class="item" v-if="details.cityName&&details.areaName">
                <img src="https://static.dasdt.cn/images/adress3.png">
                <span>{{ details.cityName }}·{{ details.areaName }}</span>
              </div>
              <div class="item">
                <img src="https://static.dasdt.cn/images/education.png">
                <span>{{ details?.education }}</span>
              </div>
              <div class="item">
                <img src="https://static.dasdt.cn/images/experience.png">
                <span>{{ details?.jobExp }}</span>
              </div>
            </div>
          </div>
          <div class="btnList">
            <div class="star" @click="eventCollection" v-if="!details?.isCollection">
              <img src="https://static.dasdt.cn/images/star2.png">
              <span>收藏</span>
            </div>
            <div class="star2" @click="eventCollection" v-else>
              <img src="https://static.dasdt.cn/images/star5.png">
              <span>已收藏</span>
            </div>
            <div class="item" v-if="!details?.isDeliver" @click="deliver">
              投递简历
            </div>
            <div class="deliver2" v-else>已投递</div>
          </div>
        </div>
        <div class="titles">岗位描述</div>
        <div class="tagList">
          <div class="item" v-for="item in details?.tagList" :key="item">
            {{ item }}
          </div>
        </div>
        <div class="responsibilityWrap">
          <div class="responsibility">岗位职责：</div>
          <div class="responsibility" style="white-space: pre-wrap;">{{ details?.duty }}</div>
        </div>
        <div class="responsibilityWrap">
          <div class="responsibility">任职要求：</div>
          <div class="responsibility" style="white-space: pre-wrap;">{{ details?.required }}</div>
        </div>
        <div class="borderss"></div>
        <div class="titles" style="margin-top: 20px">公司信息</div>
        <div class="companyInfo">
          <img style="border-radius: 6.77px" :src="info?.logo?.url">
          <div class="right">
            <div class="name">{{ info?.name }}</div>
            <div style="display: flex">
              <div class="desc" v-for="item in info?.tags" :key="item">{{ item }}<span>·</span></div>
            </div>
          </div>
        </div>
        <div class="borderss"></div>
        <div class="titles" style="margin-top: 20px">工作地址</div>
        <div class="adresss">
          <div class="item">
            <img src="https://static.dasdt.cn/images/adress2.png">
            <span class="adressShengLue">{{ details?.address }}</span>
          </div>
          <div class="item">
            <img style="margin-right: 2px" src="https://static.dasdt.cn/images/phone2.png">
            <span>联系电话：{{ info?.phone }}</span>
          </div>
        </div>
        <div style="height: 300px;width: 100%;margin-top: 16px" v-show="details">
          <MapContainer id="container2" :info="details"/>
        </div>
        <div class="ViewMores" @click="goDetails(details.id)">查看更多信息</div>
      </div>
    </div>
    <el-dialog
        :visible.sync="dialogVisible"
        center
        width="1200px"
        @open="initMap"
        :before-close="handleClose">
      <div class="mapDialog" slot="footer">
        <div style="color: #141414;font-size: 18px;font-weight: 500;margin-bottom: 16px">公司地址</div>
        <div class="adresss">
          <div class="item">
            <img src="https://static.dasdt.cn/images/adress2.png">
            <span>{{ info?.address }}</span>
          </div>
          <div class="item">
            <img style="margin-right: 2px" src="https://static.dasdt.cn/images/phone2.png">
            <span>联系电话：{{ info?.phone }}</span>
          </div>
        </div>
        <div style="height: 480px;width: 1100px;margin-top: 16px">
          <!--          <MapContainer id="container2" :info="info"/>-->
        </div>
      </div>

    </el-dialog>
  </div>
</template>
<script>
import Appointment from "@/views/enterpriseDetails/appointment.vue";
import MapContainer from "@/components/MapContainer/index.vue";
import {cancelByJid, memberCollection, jobMember} from "@/api/position";

export default {
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
  },
  components: {MapContainer, Appointment},
  data() {
    return {
      current: 0,
      dialogVisible: false,
      show: false,
      details: {}
    }
  },
  created() {
    setTimeout(() => {
      console.log(this.info.jobList?.[0])
      this.details = this.info.jobList?.[0]
    }, 1000)

  },
  mounted() {
    this.details = this.info.jobList?.[0]
  },
  methods: {
    async deliver() {
      if(this.$store.getters.userInfo){
        const info = this.$store.getters.userInfo
        if(info.customJobTag.length === 0 || !info.birthDay || !info.email || info.working.length === 0 || info.project.length === 0 || info.cateIdsComment.length === 0 || !info.expectSalary?.lowSalary || !info.industry || !info.jobType) {
          this.$message({
            message: '请先完善个人简历',
            type: 'warning'
          });
          this.$router.push('/PersonalCenter')
          return
        }
      }
      const res = await jobMember({
        advantages: '',
        jid: this.details.id,
        bid: this.details.bid,
      })
      if (res.code === 'A00000') {
        this.details.isDeliver = !this.details.isDeliver
        this.$message({
          message: '投递成功',
          type: 'success'
        })
      }

      console.log(res)
    },
    goDetails(id) {
      console.log(id)
      this.$router.push({path: '/positionDetails', query: {id}})
    },
    async eventCollection() {
      if (!this.details.isCollection) {
        const {data} = await memberCollection({type: 'POSITION', jid: this.details.id})
        console.log(data)
        this.$message({
          message: '收藏成功',
          type: 'success'
        });
        this.details.isCollection = !this.details.isCollection
      } else {
        const {data} = await cancelByJid(this.details.id)
        console.log(data)
        this.$message({
          message: '取消成功',
          type: 'success'
        });
        this.details.isCollection = !this.details.isCollection
      }
    },
    clickIndex(val) {
      this.current = val
    },
    getDetails(val) {
      console.log(val)
      this.details = val
    },
    open() {
      this.dialogVisible = true

    },
    handleClose() {
      this.dialogVisible = false
    },
    initMap() {
      setTimeout(() => {
        this.show = true
      }, 1000)
    }
  }
}
</script>
<style scoped lang="scss">
.recruitmentPositions {
  .current {
    border: 1px solid #185BE0 !important;
  }

  .mapDialog {

  }

  .adresss {
    display: flex;
    align-items: center;

    .item {
      width: 50%;
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }

      span {
        color: #555555;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .detailsWrap {
    display: flex;
    gap: 16px;
    .AppointmentWrap{
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      overflow-x: hidden;
      overflow-y: auto;
    }
    .AppointmentWrap::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .details {
      margin-top: 16px;
      border-radius: 8px;
      padding: 16px;
      flex: 1;
      background: #fff;

      .ViewMores {
        margin: 32px auto;
        width: 200px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #185BE0;
        color: #185BE0;
        font-size: 14px;
        font-weight: 400;
        border-radius: 4px;
        cursor: pointer;
      }

      .companyInfo {
        display: flex;
        align-items: center;
        gap: 6px;

        img {
          width: 44px;
          height: 44px;
        }

        .right {
          display: flex;
          flex-direction: column;
          gap: 6px;

          .name {
            color: #333333;
            font-size: 16px;
            font-width: 500;
          }

          .desc {
            color: #808080;
            font-size: 14px;
            display: flex;

            span {
              display: none;
            }
          }

          .desc:not(:last-child) {
            span {
              display: block !important;
            }
          }
        }
      }

      .borderss {
        margin-top: 20px;
        border-bottom: 1px solid #E6E6E6;
      }

      .responsibilityWrap {
        margin-top: 20px;

        .responsibility {
          color: #333333;
          font-size: 14px;
          line-height: 26px;
          font-width: 400;
        }
      }

      .titles {
        margin-bottom: 16px;
        margin-top: 32px;
        color: #141414;
        font-size: 18px;
        font-width: 500;

      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btnList {
          display: flex;
          align-items: center;
          gap: 14px;

          .deliver2 {
            background: rgb(154, 163, 179);
            border-radius: 4px;
            width: 88px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
          }

          .item {
            cursor: pointer;
            width: 88px;
            height: 34px;
            border-radius: 4px;
            background: #3A76FF;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 14px;
            font-width: 400;
          }

          .star2 {
            background: #364767;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            border-radius: 4px;
            padding: 0 16px;
            //width: 80px;
            height: 34px;
            cursor: pointer;

            img {
              width: 14px;
              height: 14px;
            }

            span {
              color: #ADADAD;
              font-size: 14px;
              font-width: 400;
            }
          }

          .star {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            border: 1px solid #3A76FF;
            border-radius: 4px;
            width: 80px;
            height: 34px;
            cursor: pointer;

            img {
              width: 14px;
              height: 14px;
            }

            span {
              color: #3A76FF;
              font-size: 14px;
            }
          }
        }

        .info {
          .name {
            color: #222222;
            font-size: 20px;
            font-width: 500;
          }

          .infoList {
            margin-top: 10px;
            display: flex;
            align-items: center;
            gap: 24px;

            .item {
              display: flex;
              align-items: center;
              gap: 6px;

              img {
                width: 16px;
                height: 16px;
              }

              span {
                color: rgba($color: #000000, $alpha: 0.65);
                font-size: 14px;
                font-width: 400;
              }
            }
          }
        }
      }
    }
  }
}
</style>
